import { Skeleton } from '@material-ui/lab';

// <editor-fold desc="String Constants">
export const V2 = 'v2';

export const A2A_EXTERNAL_DDA = 'a2aExternalDda';
export const A2A_GOOD_FUNDS_OFF_ON = 'a2aGoodFundsOffOn';
export const A2A_INTERNAL_DEFAULT_FROM_OFF_ON = 'defaultA2AInternalFromOffOn';
export const A2A_INTERNAL_DEFAULT_TO_OFF_ON = 'defaultA2AInternalToOffOn';
export const A2A_INTERNAL_OFF_ON = 'internalTransferOffOn';
export const A2A_IN_OFF_ON = 'a2aInOffOn';
export const A2A_LABEL = 'A2A';
export const A2A_OUT_OFF_ON = 'a2aOutOffOn';
export const A2A_TRANSFER_FROM = 'a2aTransferFrom';
export const A2A_TRANSFER_TO = 'a2aTransferTo';
export const ACCOUNT_CLOSED = 'Account Closed';
export const ACCOUNT_NOT_FOUND = 'Account Not Found';
export const ACCOUNT_TO_ACCOUNT = 'a2a';
export const ACCOUNT_TO_ACCOUNT_INBOUND = 'a2ain';
export const ACCOUNT_TO_ACCOUNT_INTERNAL = 'a2axfer';
export const ACCOUNT_TO_ACCOUNT_NEW = 'a2anew';
export const ACCOUNT_TO_ACCOUNT_OUTBOUND = 'a2aout';
export const ACCOUNT_TO_ACCOUNT_V2 = `${V2}-${ACCOUNT_TO_ACCOUNT}`;
export const ACH = 'ACH';
export const ACTION_INSIGHTS_LABEL = 'Action Insights';
export const ACTIVE = 'active';
export const ACTIVE_LABEL = 'Active';
export const ACTIVITY = 'activity';
export const ACTIVITY_LABEL = 'Activity';
export const ADD_A2A_EXTERNAL_DDA_OFF_ON = 'addA2aExternalDdaOffOn';
export const ADD_BP_CHECK_PAYEE_OFF_ON = 'addBpCheckPayeeOffOn';
export const ADD_BP_ELECTRONIC_PAYEE_OFF_ON = 'addBpElecPayeeOffOn';
export const ADDED = 'added';
export const ADD_LABEL = 'Add';
export const ALERT = 'Alert';
export const ALIGN_LEFT = 'left';
export const ALIGN_RIGHT = 'right';
export const ALL = 'All';
export const ALL_CODE = 'all';
export const ALL_PRIV_OFF_ON = 'allPrivOffOn';
export const ALLOW_LABEL = 'Allow';
export const ALLOWED_LABEL = `${ALLOW_LABEL}ed`;
export const AMOUNT = 'Amount';
export const AMOUNT_CODE = 'amount';
export const API_KEYS_LABEL = 'API Keys';
export const API_KEYS_REQUEST = 'apikeys';
export const ASC = 'asc';
export const AUTH_DENY = 'auth_deny';
export const AUTH_FAIL = 'auth_fail';
export const AUTHORIZED = 'authorized';
export const AUTOPAY_RULE_STATUS = 'Autopay Rule Status';
export const BILL_PAY_LABEL = 'Bill Pay';
export const BASIC = 'basic';
export const BILLER_MANAGEMENT_CODE = 'billerManagement';
export const BILL_PAY = 'Bill Pay';
export const BILL_PAY_AMOUNT = 'billPayAmount';
export const BILL_PAYMENT = 'Bill Payment';
export const BILL_SAVINGS_CONFIG = 'billSavingConfig';
export const BILL_SAVINGS_PERCENT = 'billSavingPercent';
export const BOTH_CODE = 'both';
export const BP = 'BP';
export const BP_EBILL_OFF_ON = 'bpEbillOffOn';
export const BP_EXPEDITE_FEE_AMOUNT = 'bpExpediteFeeAmount';
export const BP_EXPEDITE_OFF_ON = 'bpExpediteOffOn';
export const BP_INVOICE_OFF_ON = 'bpInvoiceOffOn';
export const BP_OFF_ON = 'bpOffOn';
export const BP_PAYEE = 'bpPayee';
export const BP_PAYMENT = 'bpPayment';
export const BP_MISSED_PAYMENT = 'BP Missed Payment';
export const BPX_LABEL = 'BPX';
export const BPX_OFF_ON = 'bpxOffOn';
export const BUSINESS_CODE = 'business';
export const CANCEL = 'cancel';
export const CANCEL_LABEL = 'Cancel';
export const CANCELED_LABEL = `${CANCEL_LABEL}ed`;
export const CANCELED = 'canceled';
export const CHANNEL_ID = 'channelId';
export const CHANNEL_NAME_CODE = 'channelName';
export const CHANNEL_CODE = 'channel';
export const CHANNEL_LABEL = 'Channel';
export const CHECK = 'Check';
export const CHECK_CODE = 'check';
export const CHECK_ONLY_CODE = 'checkOnly';
export const CHECK_NUM = `${CHECK} #`;
export const CITY = 'CITY';
export const CLEAR_FAILED = 'clearfailed';
export const CLOSE_LABEL = 'Close';
export const CONVERT_TO_CHECK = 'Convert To Check';
export const CONVERTED_LABEL = 'Converted';
export const CORE_DIRECT_LABEL = 'Core Direct';
export const CORE_PROCESSOR_CODE = 'coreprocessor';
export const CORE_PROCESSOR_ID = 'coreProcessorId';
export const CORE_PROCESSOR_LABEL = 'Core Processor';
export const CORS_DOMAIN_LABEL = 'CORS Domain';
export const CORS_DOMAIN_CODE = 'corsdomains';
export const CREATE_P2P_CONTACT_OFFON = 'createP2pContactOffOn';
export const CREATED_AT = 'createdAt';
export const DAILY_LIMITS = 'dailyLimits';
export const DDA = 'dda';
export const DDA_ACCOUNT_NUMBER = 'ddaAccountNumber';
export const DEBIT_CARD = 'Debit Card';
export const DEBOUNCE_DELAY = 5000;
export const DEFAULT_ID = 'defaultId';
export const DEFAULT_ID_INTEGER = 0;
export const DEFAULT_MODEL_REF = {};
export const DEFAULT_MODEL_REF_ARRAY = [];
export const DENYLIST_OFF_ON = 'denyListOffOn';
export const DELETED = 'Deleted';
export const DELETED_CODE = 'deleted';
export const DELIVER_CHECK_EXPEDITE = 'checkExpedite';
export const DELIVER_CHECK_STD = 'checkStd';
export const DELIVER_CODE = 'deliver';
export const DELIVER_ELEC_NOW = 'elecNow';
export const DELIVER_ELEC_NOW_LABEL = 'Electronic Immediate';
export const DELIVER_ELEC_TODAY = 'elecToday';
export const DELIVER_ELEC_TODAY_LABEL = 'Electronic ACH';
export const DELIVER_ELEC_STD = 'elecStd';
export const DELIVER_ELEC_STD_LABEL = 'Electronic Next Day';
export const DELIVERED = 'delivered';
export const DESC = 'desc';
export const DDA_ID_CODE = 'ddaId';
export const DESCRIPTION = 'Description';
export const DESCRIPTION_CODE = 'description';
export const DIRECT_CONNECT = 'dc';
export const DISABLED_LABEL = ' (disabled)';
export const DISBURSE_TYPE = 'disburseType';
export const DISBURSE_TYPE_A2A_GOOD_FUNDS_ACH = 'a2a-good-funds-ach';
export const DISBURSE_TYPE_A2A_XFER = 'a2a-xfer';
export const DISBURSE_TYPE_BP_BPX = 'bp-bpx';
export const DISBURSE_TYPE_BP_CAPO_BPX = 'bp-capo-bpx';
export const DISBURSE_TYPE_BP_IPAYNET_VC = 'bp-ipaynet-vc';
export const DISBURSE_TYPE_P2P_ACH = 'p2p-ach';
export const DISBURSE_TYPE_P2P_DEBIT = 'p2p-cybs';
export const DISBURSE_TYPE_P2P_DIRECT_CODE = 'p2p-direct';
export const DISBURSE_TYPE_P2P_PAYOUT = 'p2p-payout'; // FI Direct
export const DISBURSE_TYPE_P2P_VENMO = 'p2p-venmo';
export const DISPLAY_SEQUENCE = 'displaySequence';
export const DISTRIBUTOR_ID = 'distributorId';
export const DISTRIBUTOR_NAME_CODE = 'distributorName';
export const DISTRIBUTOR_CODE = 'distributor';
export const DISTRIBUTOR_LABEL = 'Distributor';
export const DUPLICATE_OFF_ON = 'duplicateOffOn';
export const ELEC_CODE = 'elec';
export const ELEC_ONLY_CODE = 'elecOnly';
export const ELEC_OR_CHECK_CODE = 'elecOrCheck';
export const ELECTRONIC = 'Electronic';
export const ELECTRONIC_VC = 'Electronic (VCN)';
export const EMAIL = 'Email';
export const EMPTY_CODE = 'empty';
export const EMPTY_LABEL = 'Empty';
export const EMPTY_SEARCH = 'Try modifying the current search parameters';
export const EP = 'ep';
export const ERROR = 'error';
export const EVENTS = 'Events';
export const EXPEDITED = 'Expedited';
export const EXPIRED = 'expired';
export const EXTERNAL_DDA = 'External DDA';
export const FEATURES = 'Features';
export const FEATURES_CODE = 'feature';
export const FEES_AMOUNT = 'feesAmount';
export const FEE_AMOUNT_LABEL = 'Fee Amount';
export const FEES_CONFIG = 'fees';
export const FEES_LABEL = 'Fees';
export const FEE_AMOUNT_OFF_ON = 'feeAmountOffOn';
export const FEES_OFF_ON = 'feeBundleOffOn';
export const FI_DIRECT_ACCOUNT_NUMBER_SUFFIX_OFF_ON =
  'fiDirectAccountNumberSuffixOffOn';
export const FI_DIRECT_CODE = 'fiDirect';
export const FI_DIRECT_LABEL = 'FI Direct';
export const FI_DIRECT_OFF_ON = 'fiDirectOffOn';
export const FI_DIRECT_RTN_OFF_ON = 'fiDirectRtnOffOn';
export const FRAUD_CODE = 'fraud';
export const FRAUD_FSP_OVERRIDES = 'fraudfspoverrides';
export const FRAUD_FSP_OVERRIDE = 'fraudfspoverride';
export const FRAUD_LABEL = 'Fraud';
export const FRAUD_MONITOR_CODE = 'fraudMonitor';
export const FRAUD_MONITOR_NOTIFY_CODE = 'notify';
export const FRAUD_OFF_ON = 'fraudOffOn';
export const FRACTIONAL_NUMBER = 'FRACTIONAL_NUMBER';
export const FSP_ALTERNATE_LOGO_CODE = 'fspAlternateLogo';
export const FSP_AUDIT_LOGS_CODE = 'fspauditlog';
export const FSP_AUDIT_LOGS_URI = 'fspauditlogs';
export const LOAN_STATEMENT_AUDIT_LOGS_CODE = 'palauditlogs';
export const FSP_CODE = 'fsp';
export const FSP_CONFIG_CODE = 'fspconfig';
export const FSP_CONFIG_LABEL = 'FSP Config';
export const FSP_CONFIG_PAY_A_LOAN_LABEL = 'FSP Pay A Loan Config ';
export const FSP_CORE_CONFIGS_CODE = 'fspcoreconfigs';
export const FSP_CORE_CONFIG_CODE = 'fspcoreconfig';
export const FSP_CORE_CONFIG_LABEL = 'FSP Core Config';
export const FSP_DATABASE = 'fspdatabase';
export const FSP_DATABASES = 'fspdatabases';
export const FSP_FRAUD_CONFIG_CODE = 'fspfraudconfig';
export const FSP_FRAUD_CONFIG_LABEL = 'FSP Fraud Config';
export const FSP_FRAUD_CONFIGS_CODE = `${FSP_FRAUD_CONFIG_CODE}s`;
export const FSP_ID = 'fspId';
export const FSP_LABEL = 'FSP';
export const FSP_LOGO_CODE = 'fspLogo';
export const FSP_NAME_CODE = 'fspName';
export const FSP_ON_HOLD_RULE = 'fsponholdrule';
export const FSP_ON_HOLD_RULES = 'fsponholdrules';
export const FSP_OTP_EVENT = 'fspotpevent';
export const FSP_PAYER_ID = 'fspPayerId';
export const FSP_PAYER_TYPE = 'fsppayertype';
export const FSP_PAYER_TYPES = 'fsppayertypes';
export const FSP_PAYNETS_CODE = 'fsppaynets';
export const FSP_PAYNET_CODE = 'fsppaynet';
export const FSP_PAYNET_LABEL = 'FSP Paynet';
export const FSP_SCHED_TRAN = 'fspschedtran';
export const FSP_SCHED_TRANS = 'fspschedtrans';
export const FSP_TYPE_BANK = 'bank';
export const FSP_TYPE_CREDIT_UNION = 'credit_union';
export const FSPRTN = 'fsprtn';
export const FSPRTNS = 'fsprtns';
export const FSPS = 'fsps';
export const FUNDING_SERVICE = 'fundingService';
export const GENERAL = 'General';
export const GENERAL_CODE = 'general';
export const GENERAL_CONFIGS_LABEL = 'General Configs';
export const GENERATED_FRACTIONAL_RTN = 'generatedFractionalRtn';
export const GET = 'Get';
export const GET_PRIV_OFF_ON = 'getPrivOffOn';
export const HIGH_RISK_A2A_TRANSFER_OFF_ON = 'highRiskA2aTransferOffOn';
export const HIGH_RISK_BILL_PAYMENT_OFF_ON = 'highRiskBillPaymentOffOn';
export const HIGH_RISK_P2P_PAYMENT_OFF_ON = 'highRiskP2pPaymentOffOn';
export const HREF = 'href';
export const ID = 'id';
export const ID_LABEL = 'ID';
export const IMMEDIATE = 'Immediate';
export const IMPORTED = 'imported';
export const IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS =
  'inServiceLessThanEqualToMonths';
export const INACTIVE = 'inactive';
export const INS_ADMIN_USER_ID = 'insAdminUserId';
export const INS_USER_ID = 'insUserId';
export const INSIGHT_OFF_ON = 'insightOffOn';
export const INTERNAL_TRANSFER_OFF_ON = 'internalTransferOffOn';
export const IP_WHITELIST_FAILED_CODE = 4179;
export const IS_EMPTY = 'isEmpty';
export const KEYBOARD_THROTTLE_DELAY = 1000;
export const LIMIT = 'Limit';
export const LIMIT_CODE = 'limit';
export const LIMIT_RULE = 'Limit Rule';
export const LIMIT_RULE_CODE = 'limitrule';
export const LIMIT_RULES = 'limitRules';
export const LIMIT_RULES_CODE = 'limitrules';
export const LIMITS = 'Limits';
export const LIMITS_CODE = 'limits';
export const LOADING = 'loading...';
export const LOAN_STATEMENTS = 'statements';
export const LOAN_TYPE = 'loan';
export const LOGIN_FORM_NAME = 'authenticate';
export const LOOPBACK_ENABLED_OFF_ON = 'loopbackEnabledOffOn';
export const LOOPBACK_OFF_ON = 'loopbackOffOn';
export const MANUAL_REFUND = 'manual_refund';
export const MAXIMUM_TRANSACTION = 'maximumTransaction';
export const MINIMUM = 'Minimum is .01';
export const MINIMUM_TRANSACTION = 'minimumTransaction';
export const MISS_A_PAYMENT_EVENT_ID = 50010;
export const MISS_A_PAYMENT_OFF_ON = 'missAPaymentOffOn';
export const MISS_A_PAYMENT_NOTIFICATION_OFF_ON =
  'missAPaymentNotificationOffOn';
export const MONTHLY_LIMITS = 'monthlyLimits';
export const NA_VALUE = 'N/A';
export const NAME_CODE = 'name';
export const NEW_TAB = '_blank';
export const NEXT_DAY = 'Next Day';
export const NEXT_LABEL = 'Next';
export const NO = 'No';
export const NONE = 'none';
export const NONE_LABEL = 'None';
export const NOTIFICATION = 'Notification';
export const OFF = 'off';
export const OFF_ON = 'OffOn';
export const OFFSET = 'offset';
export const OAUTH2 = 'oauth2';
export const ON = 'on';
export const ON_HOLD_LABEL = 'On Hold';
export const ON_HOLD_OFF_ON = 'onHoldOffOn';
export const ON_HOLD_PRIV_OFF_ON = 'onholdPrivOffOn';
export const ON_HOLD_RULE_CODE = 'onholdRule';
export const ON_HOLD_RULE_LABEL = 'On Hold Rule';
export const ON_HOLD_RULES_CODE = 'onholdrules';
export const ON_HOLD_RULES_LABEL = 'On Hold Rules';
export const ON_HOLD_VALIDATE = 'onHoldValidate';
export const ONTRAC_FRAUD_CODE = 'ontracFraud';
export const ORG_ID = 'orgId';
export const ORG_NAME_CODE = 'orgName';
export const ORGANIZATION = 'organization';
export const OTP = 'OTP';
export const OTP_ACCT_LOCKED_ERROR = 4120;
export const OTP_CANNOT_SEND = 4175;
export const OTP_COMPARE_WRONG_VALUE = 4171;
export const OTP_CONFIG_CODE = 'otpConfig';
export const OTP_CONTACT_PHONE = 'otpContactPhone';
export const OTP_DIGIT = 'otpDigit';
export const OTP_EMAIL_PHONE_CHANGE_DAYS = 'otpEmailPhoneChangeDays';
export const OTP_EVENT_LABEL = 'OTP Event';
export const OTP_EVENTS_CODE = 'otpEvents';
export const OTP_EVENTS_LABEL = 'OTP Events';
export const OTP_EXPIRE_SECONDS = 'otpExpireSeconds';
export const OTP_EXPIRED_CODE = 4172;
export const OTP_FSP_OVERRIDES = 'otpfspoverrides';
export const OTP_HIGH_RISK_BILL_PAY_AMOUNT = 'otpHighRiskBillPayAmount';
export const OTP_HIGH_RISK_TRANSFER_TO_AMOUNT = 'otpHighRiskTransferToAmount';
export const OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT =
  'otpHighRiskTransferFromAmount';
export const OTP_HIGH_RISK_P2P_AMOUNT = 'otpHighRiskP2PAmount';
export const OTP_INCORRECT_CODE = 4173;
export const OTP_LABEL = 'One-Time Passcode';
export const OTP_LOCKOUT_SECONDS = 'otpLockoutSeconds';
export const OTP_MAX_SEND_TRIES = 'otpMaxSendTries';
export const OTP_MAX_TRIES = 'otpMaxTries';
export const OTP_MAX_TRIES_ERROR = 4174;
export const OTP_NEW_USER_DAYS = 'otpNewUserDays';
export const OTP_OFF_ON = 'otpOffOn';
export const OTP_REQUIRED_ERROR = 4170;
export const FSP_PAL_CONFIG_CODE = 'palConfig';
export const PAY_A_LOAN = 'payALoan';
export const PAY_A_LOAN_LABEL = 'Pay A Loan';
export const PAY_A_LOAN_OFF_ON = 'palOffOn';
export const PAL_INTERNAL_ACCOUNT = 'PAL Internal Account';
export const PAL_EXTERNAL_ACCOUNT = 'PAL External Account';
export const PAL_DEBIT_CARD = 'PAL Debit Card';
export const PAL_FUNDING_TYPE_INTERNAL_ACCOUNT = 'pal-ddaCoreDc';
export const PAL_FUNDING_TYPE_EXTERNAL_ACCOUNT = 'pal-extDdaAch';
export const PAL_FUNDING_TYPE_DEBIT_CARD = 'pal-Debitcard';
export const PAYER_LIMITS_CODE = 'payerlimit';
export const P2P_AMOUNT = 'p2pAmount';
export const P2P_CONFIG = 'p2pConfig';
export const P2P_DIRECT_OFF_ON = 'p2pDirectOffOn';
export const P2P_LABEL = 'P2P';
export const P2P_MAX_SPLIT_PAY_CONTACT = 'p2pMaxSplitPayContact';
export const P2P_MAX_TRIES = 'p2pMaxTries';
export const P2P_OFF_ON = 'p2pOffOn';
export const P2P_PAYMENT = 'p2pPayment';
export const P2P_PAYOUT_LABEL = 'FI Direct';
export const P2P_REALTIME_FEE_AMOUNT = 'p2pRealtimeFeeAmount';
export const P2P_REALTIME_HOLD_ACCOUNT_NUMBER = 'p2pRealtimeHoldAccountNumber';
export const P2P_REALTIME_HOLD_ACCOUNT_ROUTING =
  'p2pRealtimeHoldAccountRouting';
export const P2P_REALTIME_HOLD_ACCOUNT_TYPE = 'p2pRealtimeHoldAccountType';
export const P2P_REALTIME_OFF_ON = 'p2pRealtimeOffOn';
export const P2P_RECIPIENT = 'p2pRecipient';
export const P2P_SECURITY_QUESTION_OFF_ON = 'p2pSecurityQuestionOffOn';
export const P2P_VENMO_FSP_ACCOUNT_NUMBER = 'p2pVenmoFspAccountNumber';
export const P2P_VENMO_FSP_ACCOUNT_ROUTING = 'p2pVenmoFspAccountRouting';
export const P2P_VENMO_FSP_ACCOUNT_TYPE = 'p2pVenmoFspAccountType';
export const PAL_ESCROW_DERIVED = 'palescrow';
export const PAL_INTEREST_DERIVED = 'palinterest';
export const PAL_PRINCIPAL_DERIVED = 'palprincipal';
export const PAL_CONFIG_CODE = 'palConfig';
export const PAL_CONFIG_FEE_AMOUNT_TRANSACT_ON_BEHALF = 'feeTransactOnBehalf';
export const PASSWORD = 'password';
export const PAY_A_LOAN_CODE = 'payALoan';
export const PAY_FAILED = 'pay_failed';
export const PAYEE = 'Payee';
export const PAYEE_CODE = 'payee';
export const PAYEE_STATUS = 'payeeStatus';
export const PAYEE_STATUS_ACTIVE = 'active';
export const PAYER_FRAUD_OVERRIDES = 'payerfraudoverrides';
export const PAYER_PREFIX = 'payer_';
export const PAYER_STATUS_ACTIVE = 'active';
export const PAYER_STATUS_INACTIVE = 'inactive';
export const PAYER_STATUS_SUSPENDED = 'suspended';
export const PAYER_TYPE = 'payerType';
export const PAYER_TYPES_CODE = 'payerTypes';
export const PAYER_USER_STATUS_ACTIVE = 'active';
export const PAYER_USER_STATUS_INACTIVE = 'inactive';
export const PAYMENT = 'Payment';
export const PAYMENT_TYPE_LABEL = 'Payment Type';
export const PAYNET_CODE = 'paynet';
export const PAYNET_ID_CODE = 'paynetId';
export const PAYNET_LABEL = 'Paynet';
export const PAYNETS_CODE = 'paynets';
export const PAYNETS_LABEL = 'Paynets';
export const PCE_CONFIG = 'pceConfig';
export const PCE_OFF_ON = 'pceOffOn';
export const PERSON = 'person';
export const PERSON_CODE = 'person';
export const PERSON_PAY = 'Person Pay';
export const PERSON_TO_PERSON = 'p2p';
export const PILOT_ENABLED_OFF_ON = 'pilotEnabledOffOn';
export const PILOT_MODE_ENABLED = 'Pilot Mode Enabled';
export const PRIVILEGE_DERIVED = 'privilegeDerived';
export const PROCESSED = 'processed';
export const PRODUCTS = 'products';
export const READ_ONLY = 'readonly';
export const READY_TO_SEND = 'ready_to_send';
export const REAL_TIME = 'Real Time';
export const REAL_TIME_FEE = `${REAL_TIME} Fee`;
export const REASON_CODE = 'reason';
export const REASONS_CODE = 'reasons';
export const RECIPIENT = 'Recipient';
export const RECIPIENT_STATUS_ACTIVE = 'active';
export const REDUCE_MY_BILL = 'Reduce My Bill';
export const REDUCE_MY_BILL_EVENT_ID = 50000;
export const REDUCE_MY_BILL_OFF_ON = 'reduceMyBillOffOn';
export const REDUCE_MY_BILL_NOTIFICATION_OFF_ON =
  'reduceMyBillNotificationOffOn';
export const REFUND_FAILED = 'refund_failed';
export const REFUND_SENT = 'refund_sent';
export const REFUNDED = 'refunded';
export const RELEASE = 'Release';
export const RELEASE_PRIV_OFF_ON = 'releasePrivOffOn';
export const REQUIRED_OFF_ON = 'requiredOffOn';
export const REMATCH_CODE = 'rematch';
export const REMOVED = 'removed';
export const REQUIRED = 'Required';
export const RESET_FSP_CONFIG_CORE = 'resetfspcoreconfig';
export const RESET_FSP_PAYNETS = 'resetfsppaynets';
export const RESET_FSP_RTNS = 'resetfsprtns';
export const RESET_LIMIT_RULES = 'resetlimitrules';
export const RESET_LIMITS = 'resetlimits';
export const RESET_ON_HOLD_RULES = 'resetonholdrules';
export const RESET_PAYER_FRAUD_OVERRIDES = 'resetpayerfraudoverrides';
export const RESET_PAYER_TYPE = 'resetfsppayertypes';
export const RESET_SSO_URIS = 'resetssouris';
export const RESET_USER_ROLES = 'resetuserroles';
export const RETURNED = 'returned';
export const REVIEW_LABEL = 'Review';
export const ROLE_CODE = 'role';
export const ROUTING_NUMBER_ACH = 'ROUTING_NUMBER_ACH';
export const ROUTING_NUMBER_MICR = 'ROUTING_NUMBER_MICR';
export const ROUTING_NUMBER_WIRE = 'ROUTING_NUMBER_WIRE';
export const ROUTING_NUMBERS_LABEL = 'Routing Numbers';
export const ROW_CODE = 'row';
export const ROWS_PER_PAGE = [25, 50, 75];
export const ROWS_PER_PAGE_MAX = 100;
export const RTN = 'rtn';
export const RTN_LENGTH = 9;
export const RTNS = 'rtns';
export const RULE = 'Rule';
export const RULE_DESCRIPTION = 'Rule Description';
export const RULE_VALUE_CODE = 'ruleValue';
export const SCHEDULE = 'schedule';
export const SCHEDULED = 'Scheduled';
export const SELECTED_ORG_ID_CODE = 'selectedOrgId';
export const SETTINGS = 'Settings';
export const SHOW_CHILDREN_TIMEOUT = 500;
export const SCOPE_CODE = 'scope';
export const SCOPE_FILTER_TYPE = 'scopehiertype';
export const SCOPE_FILTER_ID = 'scopehierid';
export const SSO_A2A_OFF_ON = 'ssoA2AOffOn';
export const SSO_BP_OFF_ON = 'ssoBPOffOn';
export const SSO_CODE = 'sso';
export const SSO_OVERWRITE = 'ssoOverWrite';
export const SSO_OVERWRITE_PREVIEW = 'ssoOverWritePreview';
export const SSO_P2P_OFF_ON = 'ssoP2POffOn';
export const SSO_PAL_OFF_ON = 'ssoPalOffOn';
export const SSO_URI = 'ssouri';
export const SSO_URI_LABEL = 'SSO URI';
export const SSO_URIS = 'ssouris';
export const SSO_URIS_LABEL = `${SSO_URI_LABEL}s`;
export const STATE = 'STATE';
export const SUSPEND_LABEL = 'Suspend';
export const TEXT_MESSAGE = 'Text Message';
export const TITLE = 'Ontrac';
export const TRANSACT_AS_PAYER_LABEL = 'Transact As Payer';
export const TRANSACT_ON_BEHALF_A2A_OFF_ON = 'transactA2AOffOn';
export const TRANSACT_ON_BEHALF_BP_OFF_ON = 'transactBPOffOn';
export const TRANSACT_ON_BEHALF_CONFIG = 'transactOnBehalfConfig';
export const TRANSACT_ON_BEHALF_LABEL = 'Transact On Behalf';
export const TRANSACT_ON_BEHALF_P2P_OFF_ON = 'transactP2POffOn';
export const TRANSACT_ON_BEHALF_PAL_OFF_ON = 'transactPalOffOn';
export const TRANSACT_ON_BEHALF_OFF_ON = 'transactOnBehalfOffOn';
export const TRANSACTION_LABEL = 'Transaction';
export const TRANSACTION_REVIEW_CODE = 'transactionReview';
export const TRANSACTION_TYPE = 'transactionType';
export const TRANSACTION_TYPE_IDENTIFIER = 'transactionTypeIdentifier';
export const TRANSACTION_TYPE_A2A_IN = 'a2aIn';
export const TRANSACTION_TYPE_A2A_OUT = 'a2aOut';
export const TRANSACTION_TYPE_A2A_NEW = 'a2aNew';
export const TRANSACTION_TYPE_A2A_XFER = 'a2aXfer';
export const TRANSACTION_TYPE_BILL_PAY = 'bp';
export const TRANSACTION_TYPE_PERSON_TO_PERSON = 'p2p';
export const TRANSACTION_TYPE_PAL = 'pal';
export const TRANSACTION_TYPE_PAL_PRINCIPAL = 'palPrincipal';
export const TRANSACTION_TYPE_PAL_INTEREST = 'palInterest';
export const TRANSACTION_TYPE_PAL_ESCROW = 'palEscrow';
export const TRANSFER_AMOUNT = 'transferAmount';
export const TRANSFER_FROM = 'Transfer From';
export const TRANSFER_FROM_AMOUNT = 'transferFromAmount';
export const TRANSFER_MONEY = 'Transfer Money';
export const TRANSFER_MONEY_IN = `${TRANSFER_MONEY} In`;
export const TRANSFER_MONEY_INTERNAL = `${TRANSFER_MONEY} Internal`;
export const TRANSFER_MONEY_NEW = `${TRANSFER_MONEY} New`;
export const TRANSFER_MONEY_OUT = `${TRANSFER_MONEY} Out`;
export const TRANSFER_TO = 'Transfer To';
export const TRANSFER_TO_AMOUNT = 'transferToAmount';
export const TRUSTLY_PROVIDER = 'trustly';
export const TYPE = 'Type';
export const TYPE_CODE = 'type';
export const UNAVAILABLE_VALUE = 'Unavailable';
export const UNDELIVERABLE_CODE = 'undeliverable';
export const UNDELIVERABLE_LABEL = 'Undeliverable';
export const UNKNOWN = 'Unknown';
export const UPD_ADMIN_USER_ID = 'updAdminUserId';
export const UPD_USER_ID = 'updUserId';
export const UPDATE_LABEL = 'Update';
export const UPDATE_P2P_CONTACT_EMAIL_PHONE_OFF_ON =
  'updateP2pContactEmailPhoneOffOn';
export const UPDATED = 'updated';
export const UPDATED_AT = 'updatedAt';
export const USD = 'USD';
export const USER_ROLE = 'User Role';
export const USER_ROLE_CODE = 'userrole';
export const USER_ROLES = `${USER_ROLE}s`;
export const USER_ROLES_CODE = 'userroles';
export const USERNAME = 'username';
export const USPS_RETURNED = 'uspsreturned';
export const VENMO = 'Venmo';
export const VENMO_CODE = 'venmo';
export const VENMO_DIRECT = 'Venmo Direct';
export const VENMO_DIRECT_CODE = 'venmoDirect';
export const VENMO_SENT_BY = `Sent by ${VENMO}`;
export const WEEKLY_LIMITS = 'weeklyLimits';
export const WRITE = 'write';
export const Z_FSP_ID = 'zfspId';
export const ZERO_DOLLAR_BALANCE =
  'Zero Dollar Balance (no minimum amount due)';

// dependent variables
export const BILL_PAY_CODE_V2 = `${V2}-${TRANSACTION_TYPE_BILL_PAY}`;
export const P2P_CODE_V2 = `${V2}-${TRANSACTION_TYPE_PERSON_TO_PERSON}`;
export const PAL_CODE_V2 = `${V2}-${TRANSACTION_TYPE_PAL}`;
// </editor-fold>

export const defaultFSP = { id: Number.NaN, title: '' };
export const defaultOrg = {
  adminGroupId: Number.NaN,
  id: Number.NaN,
  name: '',
};

export const DRAWER_WIDTH = 240;
export const HTTP_STATUS_CODE_403 = 403; // Forbidden
export const HTTP_STATUS_CODE_404 = 404; // Not Found
export const HTTP_STATUS_CODE_412 = 412; // Precondition Failed
export const HTTP_STATUS_CODE_426 = 426; // Upgrade Required
export const MASK = '• • • • '; // https://material.io/design/communication/data-formats.html#data-redaction
export const THEME_BREAKPOINT = 'lg'; // theme breakpoint
export const VERSION = `${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD_ID}`;
export const filterByDDA = (ddaItem, statements) => {
  return statements.filter((x) => x.ddaId === ddaItem.id).sort((x) => x.id);
};

/**
 * Known system types
 */
export const types = [
  { code: ACCOUNT_TO_ACCOUNT_OUTBOUND, value: TRANSFER_MONEY_OUT },
  { code: ACCOUNT_TO_ACCOUNT_INBOUND, value: TRANSFER_MONEY_IN },
  { code: TRANSACTION_TYPE_BILL_PAY, value: BILL_PAYMENT },
  { code: TRANSACTION_TYPE_PAL, value: PAY_A_LOAN },
  { code: PERSON_TO_PERSON, value: PERSON_PAY },
];

/**
 * Products
 * @type {[{product: string, label: string, type: string}, {product: string, label: string, type: string}, {product: string, label: string, type: string}, {product: string, label: string, type: string}]}
 */
export const POSSIBLE_PRODUCTS = [
  // DO NOT CHANGE ORDER - needed for Payer Limits and Limits
  // https://payrailz.atlassian.net/browse/UI-792
  // This was originally writter for payer limits, we are adding 'value' key to match transaction types values.
  {
    label: 'Bill Payment',
    product: 'billPayAmount',
    value: 'bp',
    type: BP_OFF_ON,
  },
  {
    label: 'P2P',
    product: 'p2pAmount',
    value: 'p2p',
    type: P2P_OFF_ON,
  },
  {
    label: 'A2A Outbound',
    product: 'transferToAmount',
    value: 'a2a',
    type: A2A_OUT_OFF_ON,
  },
  {
    label: 'A2A Inbound',
    product: 'transferFromAmount',
    value: 'a2a',
    type: A2A_IN_OFF_ON,
  },
  {
    label: 'PAL Internal Account',
    product: 'palDdaAmount',
    value: 'payALoan',
    type: PAY_A_LOAN_OFF_ON,
  },
  {
    label: 'PAL External Account',
    product: 'palExternalDdaAmount',
    value: 'payALoan',
    type: PAY_A_LOAN_OFF_ON,
  },
  {
    label: 'PAL Debit Card',
    product: 'palExternalCardAmount',
    value: 'payALoan',
    type: PAY_A_LOAN_OFF_ON,
  },
];

/**
 * US States
 */
export const STATES = [
  'AA',
  'AE',
  'AK',
  'AL',
  'AP',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MH',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'PW',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

/**
 * The "data" URL scheme
 * https://tools.ietf.org/html/rfc2397
 */
export const dataUrlPng = 'data:image/png;base64,';
export const emptyBase64PngImg =
  'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=';

/**
 * Info from SSO-UI
 * - src/billpay/SelectBiller/BillerListItem.js
 */
export const billerSetImageHeight = 120;
export const billerSetImageWidth = 180;

// <editor-fold desc="Helper functions">
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const combinerArray = (r) => r ?? DEFAULT_MODEL_REF_ARRAY;

export const combinerObject = (r) => r ?? DEFAULT_MODEL_REF;

export const formatSuccessMessage = ({ key, name }, str, multiple = false) => {
  return ({ response }) => {
    const value = response[key];

    return `${name}${multiple ? '(s)' : ''} ${
      multiple ? '' : value ? `"${value}"` : ''
    } ${multiple ? 'were' : 'was'} successfully ${str}`;
  };
};

/**
 * Method taken directly from ssoui code
 */
const schemes = ['data', 'http'];
export const getLogoSrc = (logo) => {
  const logoSignature = logo?.slice(0, 4);

  if (schemes.includes(logoSignature)) {
    return logo;
  }

  return `${dataUrlPng}${logo ?? emptyBase64PngImg}`;
};

export const getBase64WithOutDataUrl = (base64Str) => {
  return base64Str?.startsWith(dataUrlPng)
    ? base64Str.substr(dataUrlPng.length)
    : base64Str;
};

export const getDeliverCodes = (transactionType, includeAllCode = false) => {
  let codes = [];

  switch (transactionType) {
    case TRANSACTION_TYPE_A2A_IN:
    case TRANSACTION_TYPE_A2A_OUT:
    case TRANSACTION_TYPE_A2A_NEW:
      codes = [DELIVER_ELEC_STD];
      break;

    case TRANSACTION_TYPE_A2A_XFER:
      codes = [DELIVER_ELEC_NOW];
      break;

    case TRANSACTION_TYPE_BILL_PAY:
      codes = [
        DELIVER_ELEC_NOW,
        DELIVER_ELEC_STD,
        DELIVER_CHECK_STD,
        DELIVER_CHECK_EXPEDITE,
      ];
      break;

    case TRANSACTION_TYPE_PERSON_TO_PERSON:
      codes = [DELIVER_ELEC_NOW, DELIVER_ELEC_STD];
      break;

    default:
      break;
  }

  return includeAllCode ? [...codes, ALL_CODE] : codes;
};

export const getSecondArgument = (firstArg, secondArg) => secondArg;

export const handleStopPropagation = (event) => {
  if (event?.stopPropagation) {
    event.stopPropagation();
  }
};

export const isEqual = (str1, str2) => {
  return str1?.toUpperCase() === str2?.toUpperCase();
};

export const isTrueObject = (value) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const widthToXs = (width) => {
  let xs = 3;

  if (width < 1500) {
    xs = 2;

    if (width < 1200) {
      xs = 3;

      if (width < 900) {
        xs = 4;

        if (width < 600) {
          xs = 6;
        }
      }
    }
  }

  return xs;
};

export const SkeletonIcon = () => {
  return <Skeleton height={24} variant="rect" width={24} />;
};
// </editor-fold>
